import axios from 'axios';
import camelcase from 'camelcase';
import * as _ from 'lodash';

import cabinsImages from '@/storage/cabins-imgs.json';
import cabinsNames from '@/storage/cabins-names.json';

export class Api {
  constructor(t) {
    if (!t) {
      console.warn('API initialized without the ability to translate.');
      this.$t = () =>
        console.error(
          'API tried to translate without the translation function.'
        );
    } else {
      this.$t = t;
    }
  }

  async getPromoInfo(code) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/promo/${code}/`
    );
    return resp.data.amount;
  }

  async getDestinations() {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/destinations`
    );
    const data = resp.data.map((destination) => {
      destination.photo_url = `/assets/imgs/destinations/${destination.code
        .toLowerCase()
        .split(' ')
        .join('-')}.jpg`;
      return destination;
    });

    return [
      {
        code: this.$t('LABELS.anywhere'),
        added: true,
        name: {
          cs: this.$t('LABELS.anywhere'),
          sk: this.$t('LABELS.anywhere'),
        },
      },
      ...data,
    ];
  }

  async getYachtCountries() {
    const { data } = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/yacht-countries`
    );

    const unifiedData = _.map(data, (item) => {
      const count = item.yacht_count;

      delete item.yacht_count;

      return {
        ...item,
        count,
      };
    });

    return [
      {
        code: this.$t('LABELS.anywhere'),
        added: true,
        name: {
          cs: this.$t('LABELS.anywhere'),
          sk: this.$t('LABELS.anywhere'),
        },
        count: _.sum(_.map(unifiedData, (item) => item.count)),
      },
    ].concat(unifiedData);
  }

  async getShipCruises(shipCode) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/cruises?perPage=3&shipCode=${shipCode}`
    );
    const data = resp.data.data;

    const unifiedData = await this.unifyCruises(data, true);

    return unifiedData;
  }

  async getFavoriteCruises() {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/cruises?perPage=3&orderBy=favorite&limit=3`
    );
    const data = resp.data.data;

    const unifiedData = await this.unifyCruises(data, true);

    return unifiedData;
  }

  async getPromoCruises() {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/cruises?perPage=3&onlyPromo=true&orderBy=favorite`
    );
    const data = resp.data.data;

    const unifiedData = await this.unifyCruises(data, true);

    return unifiedData;
  }

  async getCruiseDetail(cruise_code) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/cruise/${cruise_code}`
    );
    const data = resp.data;

    if (!data || data.length === 0) {
      return false;
    }

    // Unification
    let unifiedData = (await this.unifyCruises([data], true))[0];

    const descriptions = {};

    if (unifiedData.full_itinerary && unifiedData.full_itinerary.length > 0) {
      for (let itinerary_stop of unifiedData.full_itinerary) {
        const portTypes = ['arrival', 'departure'];

        for (let portType of portTypes) {
          let description;

          if (descriptions[itinerary_stop[`${portType}_port_code`]]) {
            description = descriptions[itinerary_stop[`${portType}_port_code`]];
          } else {
            description = (
              await this.getPort(itinerary_stop[`${portType}_port_code`])
            ).description;
            descriptions[itinerary_stop[`${portType}_port_code`]] = description;
          }

          itinerary_stop[`${portType}_port_description`] = description;
        }
      }
    }

    return unifiedData;
  }

  async searchCruises(filters, perPage = 10) {
    const allowedFilters = [
      'destination',
      'departureDayTo',
      'departureDayFrom',
      'adultCount',
      'childCount',
      /*'transportIncluded',*/ 'page',
      'orderBy',
      'totalComfort',
      'cruiseDaysMin',
      'cruiseDaysMax',
      'departurePort',
      'priceMin',
      'priceMax',
      'priceCurrency',
      'shipCode',
      'cruiseOneWay',
      'onlyPromo',
    ];

    const searchFilters = [];
    allowedFilters.forEach((filterName) => {
      const filterValue = filters[filterName];

      if (filterValue) {
        searchFilters.push(`${filterName}=${filterValue}`);
      }
    });

    const searchUrl = `?perPage=${perPage}&${searchFilters.join('&')}`;
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/cruises${searchUrl}`
    );

    let data = await this.unifyCruises(resp.data.data, true);
    const meta = {
      currentPage: resp.data.page,
      maxPage: resp.data.page_count,
    };

    return { meta, data };
  }

  async loadPorts(onlyUsed = false) {
    let resp;
    if (onlyUsed) {
      resp = await axios(
        `${process.env.VUE_APP_API_BASE_URL}/ports?filter=departure`
      );
    } else {
      resp = await axios(`${process.env.VUE_APP_API_BASE_URL}/ports`);
    }

    const data = resp.data;

    return data;
  }

  async initWorkflow(cruiseCode) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/workflow/cruise?cruiseCode=${cruiseCode}`
    );

    return resp.data.workflow?.id;
  }

  async getPackages(workflowId, hasNewWorkflow = false) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/workflow/fares?workflowId=${workflowId}`
    );
    if (resp.data.error && !hasNewWorkflow) {
      const newWorkflowId = await this.updateWorkflowId(workflowId);
      return this.getPackages(newWorkflowId);
    } else if (resp.data.error) {
      return { workflow: null, packages: null, cruise: null };
    }

    const packageFeatures = {
      MYALLINC: {
        cs: [
          this.$t('TEXTS.unlimited-food-drinks'),
          this.$t('TEXTS.unlimited-wellness'),
          this.$t('LABELS.other-features'),
        ],
        sk: [
          this.$t('TEXTS.unlimited-food-drinks'),
          this.$t('TEXTS.unlimited-wellness'),
          this.$t('LABELS.other-features'),
        ],
      },
      MYCRUISE: {
        cs: [
          this.$t('TEXTS.access-to-all-decks'),
          this.$t('TEXTS.ship-shopping'),
          '...',
        ],
        sk: [
          this.$t('TEXTS.access-to-all-decks'),
          this.$t('TEXTS.ship-shopping'),
          '...',
        ],
      },
    };
    const fares = resp.data.fare_list_api
      .map((fare) => {
        const newFare = {};

        newFare.code = fare.code;
        newFare.name = fare.fareDescription;

        const fare_price_single = resp.data.cruise.price_fare_single[fare.name];
        const fare_price_double = resp.data.cruise.price_fare_double[fare.name];

        if (!fare_price_single || !fare_price_double) {
          return null;
        } else {
          newFare.price_single = fare_price_single;
          newFare.price_double = fare_price_double;
        }

        newFare.features = packageFeatures[fare.code]
          ? packageFeatures[fare.code]
          : [];

        return newFare;
      })
      .filter((fare) => fare !== null);

    return {
      workflow: resp.data.workflow,
      packages: fares,
      cruise: (await this.unifyCruises([resp.data.cruise], true))[0],
    };
  }

  unifyCategoryListApi(categoryList) {
    if (Array.isArray(categoryList)) {
      return categoryList.map((c) => {
        // Find all applicable items
        const applicableItems =
          c.priceCategoryItems.componentDetails[0].itemDetails
            .filter((item) => item.itemApplicability) // Only include items with itemApplicability true
            .map(
              (item) =>
                `${item.packageCode}:${item.itemCode}:${item.itemTypeCode}`
            ); // Format each item

        // Join applicable items into a single string
        const applicableItemsCode = applicableItems.join('|');

        // Include applicable items in the code string
        return {
          code: `${c.priceCode};${c.category};${applicableItemsCode}`,
          category: c.category,
          fare: c.priceDesc,
          name: {
            cs: c.priceDesc,
            sk: c.priceDesc,
          },
          cabinLocation: {
            cs: 'Inside', // TODO
            sk: 'Inside', // TODO
          },
          availability: c.cabinsAvailable > 0,
          price: c.totalAllInclusiveCabinPrice,
          price_port: c.portChargesAmt,
          prices: {
            [c.priceType]: c.totalAllInclusiveCabinPrice,
          },
        };
      });
    }
    return _.reduce(
      _.values(categoryList),
      (acc, subCategories) => {
        const keys = _.keys(subCategories);

        const prices = {};
        let subCategory;

        _.forEach(keys, (key) => {
          prices[key] = subCategories[key].price;
          subCategory = subCategories[key];
        });

        return _.concat(acc, {
          ...subCategory,
          prices,
        });
      },
      []
    );
  }

  async getCabins(workflowId, adultCount, childrenCount) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/workflow/categories?workflowId=${workflowId}&adultCount=${adultCount}&childCount=${childrenCount}`
    );

    if (resp.data.error) {
      return { cabins: null, workflow: null, cruise: null };
    }

    let msc = false;

    if (resp.data.category_list_api.Cruises) {
      msc = true;
    }

    const categories = this.unifyCategoryListApi(
      resp.data.category_list_api.Cruises || resp.data.category_list_api
    );

    console.debug(categories);

    let cabins = _.map(categories, (category) => {
      const shipCode = resp.data?.cruise?.ship_code;
      const images = cabinsImages[shipCode]?.[category.code];

      console.debug(category);

      return {
        code: category.code,
        name: cabinsNames[category.code] || {
          cs: category.category || category.code,
          sk: category.category || category.code,
        },
        prices: category.prices,
        location: category.cabinLocation,
        peoplePerPrice: adultCount,
        images: _.map(
          images,
          (fileName) =>
            `/assets/imgs/cabins/${shipCode}/${category.code}/${fileName}`
        ),
        features: {
          cs: ['NEMÁM Z API', 'ŽÁDNÝ POPIS', 'KAJUTY'],
          sk: ['NEMÁM Z API', 'ŽÁDNÝ POPIS', 'KAJUTY'],
        },
        availability: category.availability,
      };
    });

    console.debug(cabins);

    if (!msc) {
      cabins = cabins.filter((cabin) => cabin.images?.length > 0);
    }

    console.debug(cabins);

    const ships = await this.getShips();
    const currentShip = ships.find(
      (ship) => ship.code === resp.data.cruise.ship_code
    );

    for (let cabin of cabins) {
      cabin.imageUrl = currentShip.img_url;
    }

    let unifiedCruise = (await this.unifyCruises([resp.data.cruise], true))[0];
    unifiedCruise.summaryList = [];
    if (resp.data.workflow.guest_count_adult > 0) {
      unifiedCruise.summaryList.push([
        this.$t('LABELS.adults-with-age'),
        resp.data.workflow.guest_count_adult,
      ]);
    }
    if (resp.data.workflow.guest_count_child > 0) {
      unifiedCruise.summaryList.push([
        this.$t('LABELS.children-with-age'),
        resp.data.workflow.guest_count_child,
      ]);
    }

    return {
      workflow: resp.data.workflow,
      cabins: cabins,
      cruise: unifiedCruise,
    };
  }

  async fetchShips() {
    const resp = await axios(`${process.env.VUE_APP_API_BASE_URL}/ships`);
    return resp.data;
  }

  async getShips() {
    const ships = await this.fetchShips();

    return [
      {
        code: this.$t('LABELS.anywhat'),
        added: true,
        name: {
          cs: this.$t('LABELS.anywhat'),
          sk: this.$t('LABELS.anywhat'),
        },
      },
      ...ships,
    ];
  }

  async getShipsWithGallery() {
    const ships = await this.fetchShips();

    for (let i = 0; i < ships.length; i++) {
      const ship = ships[i];
      const shipFiles = await this.getEntityFiles(ship.id);
      const gallery = shipFiles
        .filter((file) => file.function === 'gallery-img')
        .map((img) => {
          img.url = `${process.env.VUE_APP_API_BASE_URL}/file/${img.id}`;
          return img;
        });

      ship.gallery = gallery;
    }

    return [
      {
        code: this.$t('LABELS.anywhat'),
        added: true,
        name: {
          cs: this.$t('LABELS.anywhat'),
          sk: this.$t('LABELS.anywhat'),
        },
      },
      ...ships,
    ];
  }


  async getOffers(workflowId, cabinCode, cabinPrice, packageCode) {
    let resp;

    if (cabinCode) {
      resp = await axios(
        `${process.env.VUE_APP_API_BASE_URL}/workflow/other?workflowId=${workflowId}&categoryCode=${cabinCode}&fakeTotal=${cabinPrice}&fareCode=${packageCode}`
      );
    } else {
      resp = await axios(
        `${process.env.VUE_APP_API_BASE_URL}/workflow/other?workflowId=${workflowId}`
      );
    }

    const offers = [];

    let unifiedCruise = (await this.unifyCruises([resp.data.cruise], true))[0];
    unifiedCruise.summaryList = [];
    if (resp.data.workflow.guest_count_adult > 0) {
      unifiedCruise.summaryList.push([
        this.$t('LABELS.adults-with-age'),
        resp.data.workflow.guest_count_adult,
      ]);
    }
    if (resp.data.workflow.guest_count_child > 0) {
      unifiedCruise.summaryList.push([
        this.$t('LABELS.children-with-age'),
        resp.data.workflow.guest_count_child,
      ]);
    }
    unifiedCruise.fullPrice = resp.data.workflow.price_total;

    return {
      workflow: resp.data.workflow,
      offers: offers,
      cruise: unifiedCruise,
    };
  }

  async getSummary(workflowId) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/workflow/summary?workflowId=${workflowId}`
    );

    if (resp.data.error) {
      return { workflow: null, cruise: null, flights: null };
    }

    let unifiedCruise = (await this.unifyCruises([resp.data.cruise], true))[0];
    unifiedCruise.summaryList = [];
    if (resp.data.workflow.guest_count_adult > 0) {
      unifiedCruise.summaryList.push([
        this.$t('LABELS.adults-with-age'),
        resp.data.workflow.guest_count_adult,
      ]);
    }
    if (resp.data.workflow.guest_count_child > 0) {
      unifiedCruise.summaryList.push([
        this.$t('LABELS.children-with-age'),
        resp.data.workflow.guest_count_child,
      ]);
    }

    const categoryCode = resp.data.workflow.category_code;
    const fareCode = resp.data.workflow.fare_code;

    const categories = this.unifyCategoryListApi(
      resp.data.category_list_api.Cruises || resp.data.category_list_api
    );

    unifiedCruise.fullPrice = categories.find(
      (cabin) => cabin.code === categoryCode
    )?.prices?.[fareCode];
    resp.data.workflow.categories = categories;
    resp.data.workflow.portFee = categories.find(
      (cabin) => cabin.code === categoryCode
    )?.price_port;

    return {
      workflow: resp.data.workflow,
      cruise: unifiedCruise,
      flights: resp.data.flights,
    };
  }

  async getPassengers(workflowId) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/workflow/fill-up?workflowId=${workflowId}`
    );

    if (resp.data.error) {
      return { workflow: null, passengers: null, cruise: null };
    }

    let unifiedCruise = (await this.unifyCruises([resp.data.cruise], true))[0];
    unifiedCruise.summaryList = [];
    if (resp.data.workflow.guest_count_adult > 0) {
      unifiedCruise.summaryList.push([
        this.$t('LABELS.adults-with-age'),
        resp.data.workflow.guest_count_adult,
      ]);
    }
    if (resp.data.workflow.guest_count_child > 0) {
      unifiedCruise.summaryList.push([
        this.$t('LABELS.children-with-age'),
        resp.data.workflow.guest_count_child,
      ]);
    }

    const categoryCode = resp.data.workflow.category_code;
    const fareCode = resp.data.workflow.fare_code;
    const categories = this.unifyCategoryListApi(
      resp.data.category_list_api.Cruises || resp.data.category_list_api
    );

    unifiedCruise.fullPrice = categories.find(
      (cabin) => cabin.code === categoryCode
    )?.prices?.[fareCode];

    resp.data.passenger_list.map((pass) => {
      pass.prefix = pass.phone
        ? pass.phone.slice(0, pass.phone.length - 9)
        : '';
      pass.phone = pass.phone ? pass.phone.slice(pass.prefix.length) : '';
    });

    resp.data.workflow.components = resp.data.components;

    return {
      workflow: resp.data.workflow,
      passengers: resp.data.passenger_list,
      cruise: unifiedCruise,
    };
  }

  async setPassengers(workflowId, passengers, userId) {
    await fetch(
      `${process.env.VUE_APP_API_BASE_URL}/workflow/fill-up?workflowId=${workflowId}&clientId=${userId}`,
      {
        body: JSON.stringify({ passengerList: passengers }),
        method: 'POST',
      }
    );
  }

  async createBooking(workflowId, body) {
    const resp = await fetch(
      `${process.env.VUE_APP_API_BASE_URL}/workflow/create-booking?workflowId=${workflowId}`,
      {
        body: JSON.stringify(body),
        method: 'POST',
      }
    );

    return {
      data: await resp.json(),
    };
  }

  async updateBooking(workflowId, body) {
    const resp = await fetch(
      `${process.env.VUE_APP_API_BASE_URL}/workflow/other?workflowId=${workflowId}`,
      {
        body: JSON.stringify(body),
        method: 'POST',
      }
    );

    return {
      data: await resp.json(),
    };
  }

  async holdCabin(workflowId) {
    return await axios.post(
      `${process.env.VUE_APP_API_BASE_URL}/workflow/hold-cabin?workflowId=${workflowId}`
    );
  }

  async getBookingInfo(workflowId) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/workflow/show-booking?workflowId=${workflowId}`
    );

    if (resp.data.error) {
      return null;
    }

    return resp.data.booking;
  }

  async registerAccount(email, password) {
    try {
      await fetch(
        `${process.env.VUE_APP_API_BASE_URL}/user/${email}/${password}`,
        {
          body: `{"password":"${password}", "new_user":true}`,
          method: 'POST',
        }
      );
    } catch (e) {
      return false;
    }
    return true;
  }

  async loginUser(email, password) {
    try {
      const resp = await axios(
        `${process.env.VUE_APP_API_BASE_URL}/user/${email}/${password}`
      );
      return resp.data;
    } catch (e) {
      return false;
    }
  }

  async recoverPassword(email) {
    const resp = await axios.post(
      `${process.env.VUE_APP_API_BASE_URL}/lost-password/${email}`
    );
    return resp.status === 204;
  }

  async getDials() {
    const resp = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/`);
    return resp.data?.dials;
  }

  async getPort(portCode) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/port/${portCode}`
    );
    return resp.data;
  }

  async getRecommendedYachts(yachtId, date) {
    const filter = { departureDayFrom: date, perPage: 4, orderBy: 'soon' };
    const yachts = await this.searchYachts(filter);

    return yachts.data.filter((yacht) => yacht.id !== yachtId).slice(0, 3);
  }

  async createReservation(clientId, data) {
    const resp = await fetch(
      `${process.env.VUE_APP_API_BASE_URL}/reservation?clientId=${clientId}`,
      {
        method: 'POST',
        body: JSON.stringify(data),
      }
    );

    return {
      data: await resp.json(),
    };
  }

  // Utilities
  async unifyCruises(cruisesData, loadPortImage = false) {
    const allUnifiedData = [];
    for (let data of cruisesData) {
      if (data.itinerary_steps_simplified === undefined) {
        continue;
      }
      const unifiedData = {};
      unifiedData.cruise_id = data.cruise_id;
      unifiedData.days = data.days;
      unifiedData.transport_included = data.transport_included;
      unifiedData.image = data.image;
      unifiedData.departure_port_name = data.departure_port_name;
      unifiedData.destination_name = data.destination_name;
      unifiedData.full_itinerary = data.itinerary_steps;
      unifiedData.itinerary = data.itinerary_steps_simplified;
      unifiedData.itineraryImgUrl = data.itineraryImgUrl;
      unifiedData.departure_date = data.departure_date;
      unifiedData.arrival_date = data.arrival_date;
      unifiedData.price = data.price;
      unifiedData.cruise_code = data.cruise_code;
      unifiedData.ship_name = data.ship_name;
      unifiedData.features = data.features;
      unifiedData.promo_name = data.promo_name;
      unifiedData.imported_at = data.imported_at;
      unifiedData.import_source = data.import_source;

      if (data.price_full['My Cruise'] !== undefined) {
        unifiedData.full_price = data.price_full['My Cruise'];
      } else if (data.price_full['All Inclusive'] !== undefined) {
        unifiedData.full_price = data.price_full['All Inclusive'];
      } else if (data.price_full['Last Minute'] !== undefined) {
        unifiedData.full_price = data.price_full['Last Minute'];
      }

      if (unifiedData.full_price === undefined) {
        unifiedData.full_price =
          data.price_full[Object.keys(data.price_full)[0]];
      }

      unifiedData.full_price_single =
        data.price_full_single['My Cruise'] !== undefined
          ? data.price_full_single['My Cruise']
          : data.price_full_single['All Inclusive'] !== undefined
            ? data.price_full_single['All Inclusive']
            : data.price_full_single['Last Minute'] !== undefined
              ? data.price_full_single['Last Minute']
              : data.price_full_single[Object.keys(data.price_full_single)[0]];

      if (
        loadPortImage &&
        unifiedData.full_itinerary &&
        unifiedData.full_itinerary.length > 0
      ) {
        const portCode = unifiedData.full_itinerary[0].departure_port_code;
        const portInfo = await this.getPort(portCode);
        unifiedData.image = portInfo.img_url;
      }

      unifiedData.prices = {
        single: data.price_person,
        cabin: data.price,
      };

      unifiedData.priceDouble = data.price_double;

      Object.keys(unifiedData.itinerary).forEach((lang) => {
        const itinerary = unifiedData.itinerary[lang];

        if (itinerary[4] === null) {
          itinerary[4] = '...';
        }
      });

      unifiedData.itineraryImgUrl = data.api ? data.api.itineraryUrl : null;

      // Placeholder
      unifiedData.transport_included = true;

      allUnifiedData.push(unifiedData);
    }

    return allUnifiedData;
  }

  async updateWorkflowId(oldWorkflowId) {
    const workflows = localStorage.getItem('workflows')
      ? JSON.parse(localStorage.getItem('workflows'))
      : {};
    const cruiseCode =
      Object.keys(workflows)[Object.values(workflows).indexOf(oldWorkflowId)];
    const newWorkflowId = await this.initWorkflow(cruiseCode);
    workflows[cruiseCode] = newWorkflowId;
    localStorage.setItem('workflows', JSON.stringify(workflows));

    return newWorkflowId;
  }

  getLastMonthDayDate(date) {
    const workingDate = new Date(date);
    workingDate.setDate(1);
    workingDate.setMonth(workingDate.getMonth() + 1);
    workingDate.setTime(workingDate.getTime() - 86400000);

    return `${workingDate.getFullYear()}-${workingDate.getMonth() + 1
      }-${workingDate.getDate()}`;
  }

  async unifyYachts(yachtsData) {
    const countries = await this.getYachtCountries();
    const allUnifiedData = [];
    for (let data of yachtsData) {
      const unifiedData = {};
      unifiedData.id = data.id;
      unifiedData.code = data.code;
      unifiedData.duration = data.days;
      unifiedData.transportIncluded = data.transit;
      unifiedData.destinationName = data.destination_name;
      unifiedData.destinationCode = data.destination_code;
      unifiedData.name = data.name;
      unifiedData.yachtName = data.name;
      unifiedData.transports = {
        plane: data?.plane,
        car: data?.car,
      };
      unifiedData.description = data.description;
      unifiedData.theme = data.theme_code;
      unifiedData.themeName = data.theme_name;
      unifiedData.type = data.type_code;
      unifiedData.typeName = data.type_name;
      unifiedData.departureName = data.departure_name;
      unifiedData.departureDate = data.departure_at;
      unifiedData.arrivalDate = data.arrival_at;
      unifiedData.arrivalName = data.arrival_name;
      unifiedData.sea = data.sea;
      unifiedData.sliderName = data.slider_name;
      unifiedData.sliderDescription = data.slider_description;
      unifiedData.country = countries.find(
        (country) => country.code === data.country_code
      );
      unifiedData.label = data.label;
      unifiedData.freeUnits = {
        single: data.free_person,
        cabin: data.free_cabin,
        yacht: data.free_ship,
      };

      unifiedData.prices = {
        single: data.price_person,
        cabin: data.price_cabin,
        yacht: data.price_boat,
      };
      unifiedData.pricesFull = {
        single: data.price_person_full,
        cabin: data.price_cabin_full,
        yacht: data.price_boat_full,
      };

      const entityFiles = await this.getEntityFiles(data.id);
      const thumbnailImg = entityFiles.find(
        (file) => file.function === 'thumbnail-img'
      );
      const heroImg = entityFiles.find((file) => file.function === 'hero-img');
      const galleryImgs = entityFiles.filter(
        (file) => file.function === 'gallery-img'
      );
      const slidesImgs = entityFiles.filter(
        (file) => file.function === 'slides-img'
      );
      const slidesFile = entityFiles.find(
        (file) => file.function === 'slides-file'
      );

      if (thumbnailImg) {
        unifiedData.image = `${process.env.VUE_APP_API_BASE_URL}/file/${thumbnailImg.id}`;
      } else {
        unifiedData.image = './';
      }

      if (heroImg) {
        unifiedData.heroImage = `${process.env.VUE_APP_API_BASE_URL}/file/${heroImg.id}`;
      } else {
        unifiedData.heroImage = './';
      }

      unifiedData.gallery = galleryImgs.map((img) => {
        img.url = `${process.env.VUE_APP_API_BASE_URL}/file/${img.id}`;
        return img;
      });

      unifiedData.slides = slidesImgs.map((img) => {
        img.url = `${process.env.VUE_APP_API_BASE_URL}/file/${img.id}`;
        return img;
      });

      if (slidesFile) {
        unifiedData.slidesFile = `${process.env.VUE_APP_API_BASE_URL}/file/${slidesFile.id}`;
      }

      allUnifiedData.push(unifiedData);
    }

    return allUnifiedData;
  }

  async getEntityFiles(entityId) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/files?entityId=${entityId}`
    );
    const data = resp.data;

    return data;
  }

  async getFavoriteYachts() {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/yachts?perPage=3&orderBy=favorite`
    );
    const data = resp.data.data;

    const unifiedData = await this.unifyYachts(data, true);

    return unifiedData;
  }

  async searchYachts(filters, perPage = 5) {
    const allowedFilters = [
      'countryCode',
      'departureDayFrom',
      'departureDayTo',
      'adultCount',
      /*'transportIncluded',*/ 'page',
      'orderBy',
      'yachtDaysMax',
      'priceMin',
      'priceMax',
      'priceCurrency',
      'themeCode',
      'typeCode',
      'perPage',
    ];

    const searchFilters = [];

    allowedFilters.forEach((filterName) => {
      const filterValue = filters[filterName];

      if (filterValue) {
        searchFilters.push(`${filterName}=${filterValue}`);
      }
    });

    const searchUrl = `?perPage=${perPage}&${searchFilters.join('&')}`;
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/yachts${searchUrl}`
    );

    let data = await this.unifyYachts(resp.data.data, true);
    const meta = {
      currentPage: resp.data.page,
      maxPage: resp.data.page_count,
    };

    return { meta, data };
  }

  async getYachtDetail(yachtCode) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/yacht/${yachtCode}`
    );
    const data = resp.data;

    if (!data || data.length === 0) {
      return false;
    }

    // Unification
    let unifiedData = (await this.unifyYachts([data], true))[0];

    return unifiedData;
  }

  async loadOrders(email, pass) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/user/${email}/${pass}/orders/`
    );
    const states = {
      completed: this.$t('STATUSES.completed'),
      cancelled: this.$t('STATUSES.cancelled'),
      cancelledPaidDeposit: this.$t('STATUSES.cancelled'),
      cancelledPaidFull: this.$t('STATUSES.cancelled'),
      paidDeposit: this.$t('STATUSES.paid-deposit'),
      paidFull: this.$t('STATUSES.paid-full'),
      waitingForPayment: this.$t('STATUSES.waiting-for-payment'),
    };

    const data = [];
    for (let i = 0; i < resp.data.length; i++) {
      const dataItem = resp.data[i];
      const newDataItem = {};
      Object.keys(dataItem).forEach((key) => {
        newDataItem[camelcase(key)] = dataItem[key];
      });
      newDataItem.type = newDataItem.workflow?.id
        ? 'cruiser'
        : newDataItem.reservation?.created_at
          ? 'yacht'
          : null;
      newDataItem.stateName = states[newDataItem.state] ?? newDataItem.state;

      const files = await this.getEntityFiles(newDataItem.id);
      newDataItem.files = files.filter(
        (file) => file.function === 'order-file'
      );

      if (newDataItem.type === 'yacht') {
        let reservationType;
        if (newDataItem.reservation.count_cabin > 0) {
          reservationType = this.$t('LABELS.cabin');
        } else if (newDataItem.reservation.count_person > 0) {
          reservationType = this.$t('LABELS.person');
        } else if (newDataItem.reservation.count_ship > 0) {
          reservationType = this.$t('LABELS.ship');
        }
        newDataItem.reservationType = reservationType;
        newDataItem.departureFrom =
          newDataItem.reservation.yacht.destination_name;
        newDataItem.price = newDataItem.total;
        newDataItem.arrivalAt = newDataItem.reservation.yacht.arrival_at;
        newDataItem.amount = Math.max(
          newDataItem.reservation.count_cabin,
          newDataItem.reservation.count_person,
          newDataItem.reservation.count_ship
        );

        newDataItem.passengers = [
          {
            birth_date:
              newDataItem.reservation.responsible_passenger_birth_date,
            email: newDataItem.reservation.responsible_passenger_email,
            first_name:
              newDataItem.reservation.responsible_passenger_first_name,
            last_name: newDataItem.reservation.responsible_passenger_last_name,
            nationality_code:
              newDataItem.reservation.responsible_passenger_nationality_code,
            phone: newDataItem.reservation.responsible_passenger_phone,
            sex: newDataItem.reservation.responsible_passenger_sex,
            type: 'Adult',
          },
        ];
      } else if (newDataItem.type === 'cruiser') {
        newDataItem.price = newDataItem.total;
        newDataItem.adults = newDataItem.workflow.guest_count_adult;
        newDataItem.children = newDataItem.workflow.guest_count_child;
        newDataItem.departureFrom =
          newDataItem.workflow.cruise?.departure_port_name;
        newDataItem.arrivalAt = newDataItem.workflow.cruise?.arrival_date;
        newDataItem.passengers = newDataItem.workflow.guest_list;

        newDataItem.categoryListApi =
          _.isEqual(newDataItem.categoryListApi, []) ||
          this.unifyCategoryListApi(newDataItem.categoryListApi);
        const portFee =
          typeof newDataItem.categoryListApi === 'object'
            ? newDataItem.categoryListApi.find(
              (cabin) => cabin.code === newDataItem.workflow?.category_code
            )?.price_port
            : null;
        if (portFee) {
          const usedCurrency = Object.keys(portFee).find(
            (currency) =>
              portFee[currency].currency == newDataItem.price.currency
          );

          newDataItem.price.amount_decimal +=
            portFee?.[usedCurrency].amount_decimal;
          newDataItem.price.amount_int += portFee?.[usedCurrency].amount_int;
        }
      }

      data.push(newDataItem);
    }

    return data.filter((x) => x.price);
  }

  async updateUser(email, password, userData) {
    let status = false;
    try {
      const resp = await fetch(
        `${process.env.VUE_APP_API_BASE_URL}/user/${email}/${password}`,
        {
          body: JSON.stringify(userData),
          method: 'POST',
        }
      );
      status = resp.ok;
    } catch {
      return false;
    }

    return status;
  }

  async unifyContent(data, typeCode) {
    const unifiedData = {};

    const contentFiles = await this.getEntityFiles(data.id);

    unifiedData.heroImage = contentFiles
      .filter((file) => file.function === 'hero-img')
      .map((file) => {
        file.url = `${process.env.VUE_APP_API_BASE_URL}/file/${file.id}`;
        return file;
      })[0];
    unifiedData.gallery = contentFiles
      .filter((file) => file.function === 'gallery-img')
      .map((file) => {
        file.type = 'image';
        file.src = `${process.env.VUE_APP_API_BASE_URL}/file/${file.id}`;
        file.thumb = `${process.env.VUE_APP_API_BASE_URL}/file/${file.id}`;
        return file;
      });

    unifiedData.title = data.title;
    unifiedData.description = data.description;
    unifiedData.heading = data.headline;
    unifiedData.subheading = data.lead;
    unifiedData.content = data.content;

    unifiedData.listHeading = data.list_name;

    unifiedData.yachts = [];
    unifiedData.cruises = [];

    if (typeCode.startsWith('y')) {
      for (let i = 0; i < data.yacht_code_list.length; i++) {
        unifiedData.yachts.push(
          await this.getYachtDetail(data.yacht_code_list[i])
        );
      }
    } else if (typeCode.startsWith('c')) {
      for (let i = 0; i < data.cruise_code_list.length; i++) {
        unifiedData.cruises.push(
          await this.getCruiseDetail(data.cruise_code_list[i])
        );
      }
    }

    unifiedData.cruises = unifiedData.cruises.filter(
      (cruise) => cruise?.cruise_id
    );
    unifiedData.yachts = unifiedData.yachts.filter((yacht) => yacht?.code);

    unifiedData.moreUrl = data.link_url;
    unifiedData.moreLabel = data.link_label;

    unifiedData.metaTitle = data.title;
    unifiedData.metaDescription = data.description;

    return unifiedData;
  }

  async loadContent(name, typeCode) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/content-page/${typeCode}/${name}`
    );
    if (resp.data.constructor === Array) {
      return null;
    }

    const unifiedContent = await this.unifyContent(resp.data, typeCode);

    return unifiedContent;
  }

  async getBanner(type, language, position, list = false) {
    const siteShortcut = type.slice(0, 1) + language.slice(0, 1);
    let resp;
    try {
      if (!list) {
        resp = await axios(
          `${process.env.VUE_APP_API_BASE_URL}/banner/${siteShortcut}/${position}/`
        );
        resp.data = [resp.data];
      } else {
        resp = await axios(
          `${process.env.VUE_APP_API_BASE_URL}/banners/${siteShortcut}/${position}/`
        );
      }
    } catch (e) {
      console.error(e);
      return null;
    }

    const unifiedBanners = resp.data.map((data) => {
      return {
        url: data.url,
        desktop: {
          source: `${process.env.VUE_APP_API_BASE_URL}/file/${data.fileDesktop}`,
          type: data.typeDesktop,
        },
        mobile: {
          source: `${process.env.VUE_APP_API_BASE_URL}/file/${data.fileMobile}`,
          type: data.typeMobile,
        },
      };
    });

    if (!list) {
      return unifiedBanners[0];
    }
    return unifiedBanners;
  }

  async getShipDetail(shipCode) {
    const resp = await axios(`${process.env.VUE_APP_API_BASE_URL}/ships`);
    const ship = resp.data.find((ship) => ship.code === shipCode);

    if (!ship) {
      return false;
    }

    const shipFiles = await this.getEntityFiles(ship.id);
    const galleryImages = shipFiles.filter(
      (file) => file.function == 'gallery-img'
    );
    ship.galleryImages = galleryImages.map(
      (file) => `${process.env.VUE_APP_API_BASE_URL}/file/${file.id}`
    );

    // Cabins
    const cabins = (
      await axios(`${process.env.VUE_APP_API_BASE_URL}/cabins/${ship.id}`)
    ).data;
    for (let i = 0; i < cabins.length; i++) {
      const cabin = cabins[i];

      if (!cabin.id) {
        cabin.id = '0434998e-d5bf-47a5-8927-78d2bd9e700' + i;
      }

      const cabinFiles = await this.getEntityFiles(cabin.id);
      const cabinImages = cabinFiles.filter(
        (file) => file.function == 'gallery-img'
      );
      cabins[i].cabinImages = cabinImages.map(
        (file) => `${process.env.VUE_APP_API_BASE_URL}/file/${file.id}`
      );
    }

    ship.cabins = cabins;
    ship.heroVideo = this.createFilePath(ship.hero_video);

    delete ship.hero_video;

    return ship;
  }

  async tryRegisterAccount(email, accountData = {}) {
    let resp;
    try {
      resp = await fetch(
        `${process.env.VUE_APP_API_BASE_URL}/user/${email}/auto-registration/`,
        {
          method: 'POST',
          body: JSON.stringify({ ...accountData, new_user: true }),
        }
      );
    } catch (e) {
      return false;
    }

    if (resp.status !== 200) {
      return false;
    }
    const data = await resp.json();

    return data.id;
  }

  async getFlights(workflowId) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/workflow/categories?workflowId=${workflowId}`
    );

    if (resp.data.error) {
      return { flights: [], workflow: null, cruise: null };
    }

    const localeFlightName = (flight) => {
      switch (_.toLower(flight)) {
        case 'prague': {
          return 'Praha';
        }
        case 'vienna': {
          return 'Vídeň';
        }
        case 'munich': {
          return 'Mnichov';
        }
        default: {
          return flight;
        }
      }
    };

    const flights = Object.keys(resp.data.flights).map((key) => {
      return {
        code: key,
        text: localeFlightName(resp.data.flights[key]),
      };
    });

    resp.data.workflow.components = resp.data.components;

    let unifiedCruise = (await this.unifyCruises([resp.data.cruise], true))[0];
    unifiedCruise.summaryList = [];
    if (resp.data.workflow.guest_count_adult > 0) {
      unifiedCruise.summaryList.push([
        this.$t('LABELS.adults-with-age'),
        resp.data.workflow.guest_count_adult,
      ]);
    }
    if (resp.data.workflow.guest_count_child > 0) {
      unifiedCruise.summaryList.push([
        this.$t('LABELS.children-with-age'),
        resp.data.workflow.guest_count_child,
      ]);
    }

    return {
      workflow: resp.data.workflow,
      flights: flights,
      cruise: unifiedCruise,
    };
  }

  async setFlight(workflowId, flightCode) {
    if (flightCode === null) {
      flightCode = '';
    }
    await axios(
      `${process.env.VUE_APP_API_BASE_URL}/workflow/categories?workflowId=${workflowId}&flight=${flightCode}`
    );
  }

  async getSnippet(name) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/snippet/${name}/`
    );
    return resp.data;
  }

  async getYachtasHowItWorks() {
    const { data } = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/how-it-works/ys`
    );

    return _.map(data, (item) => {
      const backgroundUrl = this.createFilePath(item.background_file);

      delete item.background_file;

      return {
        ...item,
        backgroundUrl,
      };
    }).sort((x, y) => x.order - y.order);
  }

  createFilePath(id, thumbnail = false) {
    if (!id) return '';

    const filePath = thumbnail ? 'file-thumbnail' : 'file';
    return `${process.env.VUE_APP_API_BASE_URL}/${filePath}/${id}`;
  }

  async getReviews(type, language) {
    const siteShortcut = type.slice(0, 1) + language.slice(0, 1);
    const { data } = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/review/${siteShortcut}`
    );

    return _.map(data, (item) => {
      const file = item.avatar_file;

      delete item.avatar_file;

      return {
        ...item,
        imageUrl: this.createFilePath(file, true),
      };
    }).sort((x, y) => x.order - y.order);
  }

  async getYachtasMainPageGallery() {
    const { data } = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/files/?function=homepage-carrousel`
    );

    return _.map(data, (item) => ({
      type: item.type,
      name: item.name,
      order: item.order,
      src: this.createFilePath(item.id),
      thumb: this.createFilePath(item.id, true),
    })).sort((x, y) => x.order - y.order);
  }

  async getAboutYachtingDashesInfo() {
    const { data } = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/files/?function=homepage-yachting`
    );

    return _.map(data, (item) => ({
      type: item.type,
      name: item.name,
      order: item.order,
      src: this.createFilePath(item.id),
      thumb: this.createFilePath(item.id, true),
    })).sort((x, y) => x.order - y.order);
  }

  async getMenu(type, lang, position = 'header') {
    const { data } = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/menu/${type[0]}${lang[0]}`
    );

    return _.map(data?.[position], (item) => ({
      ...item,
      url: '/kontent/' + item.url,
    }));
  }

  async searchCruisesDelegate(filters, perPage = 4) {
    const allowedFilters = [
      'destination',
      'departureDayFrom',
      'departureDayTo',
      'adultCount',
      'childCount',
      /*'transportIncluded',*/ 'page',
      'orderBy',
      'daysMax',
      'priceMin',
      'priceMax',
      'priceCurrency',
      'shipCode',
    ];

    const searchFilters = [];

    allowedFilters.forEach((filterName) => {
      const filterValue = filters[filterName];

      if (filterValue) {
        searchFilters.push(`${filterName}=${filterValue}`);
      }
    });

    const searchUrl = `?perPage=${perPage}&${searchFilters.join(
      '&'
    )}&withDelegate=true`;
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/special-cruises${searchUrl}`
    );

    let data = await this.unifyCruisesDelegate(resp.data.data);
    const meta = {
      currentPage: resp.data.page,
      maxPage: resp.data.page_count,
    };

    return { meta, data };
  }

  async getCruisesDelegateDetail(cruiseCode) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/special-cruise/${cruiseCode}`
    );
    const data = resp.data;

    if (!data || data.length === 0) {
      return false;
    }

    // Unification
    let unifiedData = (await this.unifyCruisesDelegate([data], true))[0];

    return unifiedData;
  }

  async getSpecialCruisePrices(cruiseUuid) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/special-cruise-price/${cruiseUuid}`
    );
    const data = resp.data;

    if (!data || data.length === 0) {
      return false;
    }

    // Group data by code
    const groupedData = {};
    data.forEach((item) => {
      if (!groupedData[item.code]) {
        groupedData[item.code] = [];
      }
      groupedData[item.code].push(item);
    });

    const result = Object.values(groupedData).map((group) => {
      // Find the first object with a valid description
      const validDescriptionObject = group.find((item) => {
        const cs = item.description.cs;
        const sk = item.description.sk;
        return cs && cs.trim() !== '' && sk && sk.trim() !== '';
      });

      // If a valid description is found, update all objects in the group
      if (validDescriptionObject) {
        const validDescription = validDescriptionObject.description;
        group.forEach((item) => {
          item.description = validDescription;
        });
      }

      return group;
    });

    return result;
  }

  async getSpecialCruiseItinerary(cruiseUuid) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/special-cruise-itinerary/${cruiseUuid}`
    );
    const data = resp.data;

    if (!data) {
      return false;
    }

    return data;
  }

  async sendCruisesDelegateOrderForm(body) {
    const resp = await fetch(
      `${process.env.VUE_APP_API_BASE_URL}/cruise-delegate-order-email/`,
      {
        body: JSON.stringify(body),
        method: 'POST',
      }
    );

    return {
      data: await resp.json(),
    };
  }

  async unifyCruisesDelegate(cruisesData) {
    const allUnifiedData = [];
    for (let data of cruisesData) {
      const unifiedData = {};
      unifiedData.id = data.id;
      unifiedData.code = data.code;
      unifiedData.days = data.days;
      unifiedData.destinationName = data.destination_name;
      unifiedData.destinationCode = data.destination_code;
      unifiedData.name = data.name;
      unifiedData.ship_name = data.ship_name;
      unifiedData.ship_code = data.ship_code;
      unifiedData.transports = {
        plane: data?.plane,
        bus: data?.bus,
      };
      unifiedData.conditions = data.conditions;
      unifiedData.description = data.description;
      unifiedData.departureName = data.departure_name;
      unifiedData.departureDate = data.departure_at;
      unifiedData.arrivalDate = data.arrival_at;
      unifiedData.arrivalName = data.arrival_name;
      unifiedData.sliderName = data.slider_name;
      unifiedData.sliderDescription = data.slider_description;
      unifiedData.priceIncludedText = data.price_included_text;
      unifiedData.priceExcludedText = data.price_excluded_text;
      unifiedData.noteText = data.note_text;
      unifiedData.transportText = data.transport_text;
      unifiedData.label = data.label;
      unifiedData.freeUnits = {
        single: data.free_person,
        cabin: data.free_cabin,
        ship: data.free_ship,
      };

      unifiedData.prices = {
        single: data.first_adult_min_price,
        total: data.total_price,
      };

      unifiedData.delegateInfo = {
        name: data.delegate_name,
        email: data.delegate_email,
        phone: data.delegate_phone,
        text: data.delegate_text,
      };

      const entityFiles = await this.getEntityFiles(data.id);
      const thumbnailImg = entityFiles.find(
        (file) => file.function === 'thumbnail-img'
      );
      const heroImg = entityFiles.find((file) => file.function === 'hero-img');
      const galleryImgs = entityFiles.filter(
        (file) => file.function === 'gallery-img'
      );
      const slidesImgs = entityFiles.filter(
        (file) => file.function === 'slides-img'
      );
      const slidesFile = entityFiles.find(
        (file) => file.function === 'slides-file'
      );
      const delegateImg = entityFiles.find(
        (file) => file.function === 'delegate-img'
      );

      if (thumbnailImg) {
        unifiedData.image = `${process.env.VUE_APP_API_BASE_URL}/file/${thumbnailImg.id}`;
      } else {
        unifiedData.image = './';
      }

      if (heroImg) {
        unifiedData.heroImage = `${process.env.VUE_APP_API_BASE_URL}/file/${heroImg.id}`;
      } else {
        unifiedData.heroImage = './';
      }

      if (delegateImg) {
        unifiedData.delegateImage = `${process.env.VUE_APP_API_BASE_URL}/file/${delegateImg.id}`;
      } else {
        unifiedData.delegateImage = './';
      }

      unifiedData.gallery = galleryImgs.map((img) => {
        img.url = `${process.env.VUE_APP_API_BASE_URL}/file/${img.id}`;
        return img;
      });

      unifiedData.slides = slidesImgs.map((img) => {
        img.url = `${process.env.VUE_APP_API_BASE_URL}/file/${img.id}`;
        return img;
      });

      if (slidesFile) {
        unifiedData.slidesFile = `${process.env.VUE_APP_API_BASE_URL}/file/${slidesFile.id}`;
      }

      allUnifiedData.push(unifiedData);
    }

    return allUnifiedData;
  }

  async getMSCCruise(cruiseUuid) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/msc-cruise/${cruiseUuid}`
    );
    const data = resp.data;

    if (!data) {
      return false;
    }

    return data;
  }

  async getItineraryUrl(cruiseCode) {
    const resp = await axios(
      `${process.env.VUE_APP_API_BASE_URL}/cruise-itinerary-url/${cruiseCode}`
    );
    const data = resp.data;

    if (!data) {
      return false;
    }

    return data.itinerary_url;
  }
}

// function random(min, max) {
//     return Math.floor(Math.random() * (max - min + 1) ) + min;
// }
