<template>
    <Head>
        <title>
            {{
                $t &&
                $t('TITLES.cruise-detail', {
                    cruiseName: details.departure_name?.[LANGUAGE],
                })
            }}
        </title>
        <meta
            name="description"
            :content="
                $t &&
                $t('META-DESCRIPTIONS.cruise-detail', {
                    cruiseName: details.departure_name?.[LANGUAGE],
                })
            "
        />
    </Head>

    <header
        class="header-detail header-detail--cruises-delegate"
        :style="`background: url('${details.heroImage}');background-size: cover;background-position: center;`"
    >
        <div ref="sticky-header-offset">
            <Main-nav></Main-nav>
            <div class="breadcrumbs breadcrumbs--cruises-delegate">
                <ul class="breadcrumbs__inner">
                    <li class="breadcrumbs__item">
                        <router-link to="/" class="breadcrumbs__link">
                            nautyy.{{
                                LANGUAGE === 'cs'
                                    ? 'cz'
                                    : LANGUAGE === 'sk'
                                    ? 'sk'
                                    : ''
                            }}</router-link
                        >
                    </li>
                    <li class="breadcrumbs__item">
                        <router-link
                            to="/plavby-s-delegatem/"
                            class="breadcrumbs__link"
                        >
                            {{ details.destinationName?.[LANGUAGE] }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div
            class="cruises-delegate-detail-page__header"
            ref="sticky-header-trigger"
        >
            <div class="cruises-delegate-detail-page__header-inner">
                <div class="cruises-delegate-detail-page__header-split">
                    <div
                        class="cruises-delegate-detail-page__header-content"
                        :class="{ transport: includeTransport }"
                    >
                        <div
                            class="
                                cruises-delegate-detail-page__header-part
                                cruises-delegate-detail-page__header-part--header
                            "
                        >
                            <h3 class="cruises-delegate-detail-page__heading">
                                <span
                                    class="
                                        cruises-delegate-detail-page__subheading
                                    "
                                >
                                    {{ details.destinationName?.[LANGUAGE] }}
                                </span>
                                {{ details.name?.[LANGUAGE] }}
                            </h3>
                        </div>
                        <div
                            class="
                                cruises-delegate-detail-page__header-part
                                cruises-delegate-detail-page__header-part--dates
                                cruises-delegate-detail-page__header-part--middle
                            "
                        >
                            <h3
                                class="
                                    cruises-delegate-detail-page__mini-heading
                                "
                            >
                                {{ $t('LABELS.sail-date') }}
                            </h3>
                            <p class="cruises-delegate-detail-page__date">
                                <span
                                    class="
                                        cruises-delegate-detail-page__date-arrow
                                    "
                                    >→</span
                                >
                                {{ formatDate(details.departureDate) }}
                                <span>{{
                                    details.departureName?.[LANGUAGE]
                                }}</span>
                            </p>
                            <p class="cruises-delegate-detail-page__date">
                                <span
                                    class="
                                        cruises-delegate-detail-page__date-arrow
                                    "
                                    >←</span
                                >
                                {{ formatDate(details.arrivalDate) }}
                                <span>{{
                                    details.arrivalName?.[LANGUAGE]
                                }}</span>
                            </p>
                        </div>
                        <div
                            class="
                                cruises-delegate-detail-page__header-part
                                cruises-delegate-detail-page__header-part--middle
                                cruises-delegate-detail-page__header-part--middle-last
                            "
                        >
                            <div
                                class="
                                    cruises-delegate-detail-page__header-part__row
                                "
                            >
                                <h3
                                    class="
                                        cruises-delegate-detail-page__mini-heading
                                    "
                                >
                                    {{ $t('LABELS.total-time') }}
                                </h3>
                                <p
                                    class="
                                        cruises-delegate-detail-page__header-text
                                    "
                                >
                                    {{ details.days }}
                                    {{ daysInflected(details.days) }}
                                </p>
                            </div>
                            <div
                                class="
                                    cruises-delegate-detail-page__header-part__row
                                "
                            >
                                <h3
                                    class="
                                        cruises-delegate-detail-page__mini-heading
                                    "
                                >
                                    {{ $t('LABELS.ship') }}
                                </h3>
                                <div
                                    class="
                                        cruises-delegate-detail-page__cruiser-name-container
                                    "
                                >
                                    <p
                                        class="
                                            cruises-delegate-detail-page__cruiser-name
                                        "
                                    >
                                        {{ details.ship_name?.[LANGUAGE] }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div
                            class="
                                cruises-delegate-detail-page__header-part
                                cruises-delegate-detail-page__header-part--prices
                            "
                        >
                            <div
                                class="cruises-delegate-detail-page__notice"
                                v-if="details.label?.[LANGUAGE]"
                            >
                                <svg
                                    class="
                                        cruises-delegate-detail-page__notice-icon
                                    "
                                >
                                    <use
                                        href="/assets/imgs/icons/sprite.svg#notice"
                                    ></use>
                                </svg>
                                <span
                                    class="
                                        cruises-delegate-detail-page__notice-text
                                    "
                                    >{{ details.label[LANGUAGE] }}</span
                                >
                            </div>
                        </div>
                        <div
                            class="
                                cruises-delegate-detail-page__header-part
                                cruises-delegate-detail-page__header-part--more-info
                            "
                        >
                            <p
                                class="
                                    cruises-delegate-detail-page__more-info-text
                                "
                                v-html="
                                    $t('TEXTS.contact-cruise-text', {
                                        href: `/kontakt/`,
                                    })
                                "
                            ></p>
                        </div>
                        <div
                            class="
                                cruises-delegate-detail-page__header-part
                                cruises-delegate-detail-page__header-part--transport
                            "
                            v-if="includeTransport"
                        >
                            <h4>{{ $t('TEXTS.transport-to-tour') }}</h4>
                            <p
                                v-if="this.details?.transportText?.[LANGUAGE]"
                                v-html="this.details?.transportText?.[LANGUAGE]"
                            ></p>
                            <div
                                class="cruises-delegate-detail-page__transports"
                            >
                                <div
                                    class="
                                        cruises-delegate-detail-page__transport
                                    "
                                    v-if="
                                        this.details?.transports?.plane?.[
                                            LANGUAGE
                                        ]
                                    "
                                >
                                    <svg
                                        class="
                                            cruises-delegate-detail-page__transport__icon
                                        "
                                    >
                                        <use
                                            href="/assets/imgs/icons/sprite.svg#plane"
                                        ></use>
                                    </svg>
                                    <p>
                                        {{
                                            this.details?.transports?.plane?.[
                                                LANGUAGE
                                            ]
                                        }}
                                    </p>
                                </div>
                                <div
                                    class="
                                        cruises-delegate-detail-page__transport
                                    "
                                    v-if="
                                        this.details?.transports?.bus?.[
                                            LANGUAGE
                                        ]
                                    "
                                >
                                    <img
                                        class="
                                            cruises-delegate-detail-page__transport__icon
                                        "
                                        src="/assets/imgs/icons/bus.svg"
                                    />
                                    <p>
                                        {{
                                            this.details?.transports?.bus?.[
                                                LANGUAGE
                                            ]
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cruises-delegate-detail-page__header-column">
                        <div
                            class="
                                cruises-delegate-detail-page__header-column-part
                            "
                        >
                            <CabinGroupDelegate
                                v-for="(priceIndex, index) in this.addedGroups"
                                :key="index"
                                :ref="'cabinGroup_' + index"
                                :price="this.prices[priceIndex]"
                                :currency="this.$store.currency"
                                :maxPeople="this.maxPeoplePerCabin"
                                @update-group="updateGroupData(index, $event)"
                            ></CabinGroupDelegate>
                            <div>
                                <v-select
                                    class="
                                        cruises-delegate-detail-page__cabin-select
                                    "
                                    :options="priceObjects"
                                    :reduce="
                                        (price) => priceObjects.indexOf(price)
                                    "
                                    v-model="selectedPriceIndex"
                                    :placeholder="'Vyberte'"
                                    :clearable="false"
                                >
                                    <template #option="option">
                                        {{
                                            option.fares[0].description[
                                                LANGUAGE
                                            ]
                                        }}
                                    </template>
                                    <template #selected-option="option">
                                        {{
                                            option.fares[0].description[
                                                LANGUAGE
                                            ]
                                        }}
                                    </template>
                                </v-select>
                                <div
                                    class="
                                        btn
                                        btn--blue
                                        btn--standalone-tiny
                                        btn--block
                                    "
                                    @click="handleAddPrice"
                                >
                                    {{ $t('LABELS.add-cabin') }}
                                </div>
                            </div>
                        </div>
                        <form @submit.prevent="sendOrderForm">
                            <div
                                class="
                                    cruises-delegate-detail-page__header-column-part
                                "
                            >
                                <Notification
                                    :text="error.text[LANGUAGE]"
                                    v-if="error.show"
                                    :initConfig="{ small: true }"
                                    @closed="closeNotification"
                                ></Notification>
                                <div
                                    class="
                                        cruises-delegate-order-form__order-form__inputs
                                    "
                                >
                                    <div
                                        class="
                                            cruises-delegate-order-form__order-form__input-wrapper
                                        "
                                    >
                                        <label
                                            for="first-name"
                                            class="
                                                cruises-delegate-order-form__order-form__label
                                            "
                                            >{{
                                                $t('LABELS.first-name')
                                            }}</label
                                        >
                                        <input
                                            type="text"
                                            id="first-name"
                                            class="
                                                cruises-delegate-order-form__order-form__input
                                            "
                                            v-model="firstName"
                                            required
                                        />
                                    </div>
                                    <div
                                        class="
                                            cruises-delegate-order-form__order-form__input-wrapper
                                        "
                                    >
                                        <label
                                            for="last-name"
                                            class="
                                                cruises-delegate-order-form__order-form__label
                                            "
                                            >{{ $t('LABELS.last-name') }}</label
                                        >
                                        <input
                                            type="text"
                                            id="last-name"
                                            class="
                                                cruises-delegate-order-form__order-form__input
                                            "
                                            v-model="lastName"
                                            required
                                        />
                                    </div>
                                    <div
                                        class="
                                            cruises-delegate-order-form__order-form__input-wrapper
                                        "
                                    >
                                        <label
                                            for="email"
                                            class="
                                                cruises-delegate-order-form__order-form__label
                                            "
                                            >{{ $t('LABELS.email') }}</label
                                        >
                                        <input
                                            type="email"
                                            id="email"
                                            class="
                                                cruises-delegate-order-form__order-form__input
                                            "
                                            v-model="email"
                                            required
                                        />
                                    </div>
                                    <div
                                        class="
                                            cruises-delegate-order-form__order-form__input-wrapper
                                        "
                                    >
                                        <label
                                            for="phone"
                                            class="
                                                cruises-delegate-order-form__order-form__label
                                            "
                                            >{{
                                                $t('LABELS.phone-number')
                                            }}</label
                                        >
                                        <input
                                            type="tel"
                                            id="phone"
                                            class="
                                                cruises-delegate-order-form__order-form__input
                                            "
                                            v-model="phone"
                                            required
                                        />
                                    </div>
                                </div>
                                <div
                                    class="
                                        cruises-delegate-order-form__order-form__input-wrapper
                                    "
                                >
                                    <label
                                        for="message"
                                        class="
                                            cruises-delegate-order-form__order-form__label-message
                                        "
                                        >{{ $t('LABELS.message') }}</label
                                    >
                                    <textarea
                                        id="message"
                                        class="
                                            cruises-delegate-order-form__order-form__textarea
                                        "
                                        v-model="message"
                                    ></textarea>
                                </div>

                                <div class="checkbox-agree">
                                    <input
                                        type="checkbox"
                                        class="checkbox-agree__checkbox"
                                        id="gdpr-agree"
                                        v-model="allowGDPR"
                                    />
                                    <label
                                        for="gdpr-agree"
                                        class="checkbox-agree__label"
                                        >{{ $t('TEXTS.agree-with-gdpr') }}
                                        <a
                                            href="https://info.costa-cruises.cz/ochrana-soukromi/"
                                            class="checkbox-agree__link"
                                            target="_blank"
                                            >{{ $t('TEXTS.GDPR') }}</a
                                        ></label
                                    >
                                </div>
                            </div>

                            <div class="cruises-delegate-detail-page__prices">
                                <div
                                    class="
                                        cruises-delegate-detail-page__price-wrapper
                                    "
                                    v-if="!details.isSoldOut?.single"
                                >
                                    <div
                                        class="
                                            cruises-delegate-detail-page__price
                                            cruises-delegate-detail-page__price--discounted
                                        "
                                        v-if="
                                            details.pricesFull?.single[
                                                this.$store.currency
                                            ].amount_decimal > 0
                                        "
                                        v-html="discountPriceMarkupComputed"
                                    ></div>
                                    <div
                                        class="
                                            cruises-delegate-detail-page__price
                                        "
                                        v-else
                                    >
                                        {{ formatPriceText(this.totalPrice) }}
                                    </div>
                                    <button
                                        type="submit"
                                        class="
                                            cruises-delegate-detail-page__price-btn
                                        "
                                    >
                                        {{
                                            $t(
                                                'LABELS.cruises-delegate-interested-button'
                                            )
                                        }}
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div
                            v-if="details.conditions?.[LANGUAGE]"
                            class="
                                cruises-delegate-detail-page__header-column-part
                            "
                        >
                            <div
                                class="cruises-delegate-detail-page__conditions"
                                v-html="details.conditions?.[LANGUAGE]"
                            ></div>
                        </div>
                    </div>
                </div>
                <router-link
                    :to="`/plavby-s-delegatem/${urlCrewHash}`"
                    class="
                        cruises-delegate-detail-page__back-container
                        cruises-delegate-detail-page__back-container--cruises-delegate
                    "
                >
                    <svg
                        class="
                            cruises-delegate-detail-page__back-icon
                            cruises-delegate-detail-page__back-icon--cruises-delegate
                        "
                    >
                        <use
                            href="/assets/imgs/icons/sprite.svg#back-arrow"
                        ></use>
                    </svg>
                    {{ $t('LABELS.back-to-search') }}
                </router-link>
            </div>
        </div>
    </header>
    <StickyHeaderCruiseDelegate
        :details="details"
        :url-crew-search="urlCrewSearch"
        :price="formatPriceText(this.totalPrice)"
        @submit="sendOrderForm"
    >
    </StickyHeaderCruiseDelegate>
    <main class="main main--cruises-delegate cruises-delegate-detail-page">
        <main>
            <section class="cruises-delegate-detail__section">
                <Itinerary :itinerary="this.itinerary"></Itinerary>
            </section>
            <section
                v-if="details.sliderDescription?.[LANGUAGE]"
                class="cruises-delegate-detail__section"
            >
                <div
                    class="
                        cruises-delegate-detail__section-inner
                        cruises-delegate-detail__wysiwyg
                    "
                    v-html="details.sliderDescription?.[LANGUAGE]"
                ></div>
            </section>
            <section class="cruises-delegate-detail__section">
                <div class="cruises-delegate-detail__section-inner">
                    <Gallery
                        class="content-page__gallery"
                        :images="
                            details.gallery?.map((img) => {
                                img.src = img.url;
                                return img;
                            })
                        "
                        :type="TYPE"
                        :loop="true"
                        :delay="3000"
                    ></Gallery>
                    <Banner
                        class="cruises-delegate-detail__banner"
                        :type="banner.desktop.type"
                        :type-mobile="banner.mobile.type"
                        :source="banner.desktop.source"
                        :source-mobile="banner.mobile.source"
                        :url="banner.url"
                    >
                    </Banner>
                </div>
            </section>
            <section
                v-if="this.shipDetail"
                class="cruises-delegate-detail__section"
            >
                <div class="cruises-delegate-detail__section-inner">
                    <div class="cruiser-page__information">
                        <Swiper
                            :slides-per-view="1"
                            class="
                                cabin-showcase__slider
                                cruiser-page__information-slider
                            "
                            :autoplay="{
                                delay: 3000,
                            }"
                            @swiper="saveInfoSwiper"
                        >
                            <button
                                class="
                                    cabin-showcase__slider-btn
                                    cabin-showcase__slider-btn--prev
                                "
                                :class="{
                                    'cabin-showcase__slider-btn--disabled':
                                        infoSwiper?.isBeginning,
                                    'cabin-showcase__slider-btn--hidden':
                                        infoSwiper?.isBeginning &&
                                        infoSwiper?.isEnd,
                                }"
                                @click="prevSlide(infoSwiper)"
                            >
                                <svg
                                    class="
                                        cabin-showcase__slider-icon
                                        cabin-showcase__slider-icon--left
                                    "
                                >
                                    <use
                                        href="/assets/imgs/icons/sprite.svg#simple-arrow"
                                    ></use>
                                </svg>
                            </button>
                            <Swiper-slide
                                v-for="(imgUrl, i) in shipDetail.galleryImages"
                                :key="i"
                                ><img
                                    :src="imgUrl"
                                    class="cabin-showcase__slide"
                            /></Swiper-slide>
                            <button
                                class="
                                    cabin-showcase__slider-btn
                                    cabin-showcase__slider-btn--next
                                "
                                :class="{
                                    'cabin-showcase__slider-btn--disabled':
                                        infoSwiper?.isEnd,
                                    'cabin-showcase__slider-btn--hidden':
                                        infoSwiper?.isEnd &&
                                        infoSwiper?.isBeginning,
                                }"
                                @click="nextSlide(infoSwiper)"
                            >
                                <svg
                                    class="
                                        cabin-showcase__slider-icon
                                        cabin-showcase__slider-icon--right
                                    "
                                >
                                    <use
                                        href="/assets/imgs/icons/sprite.svg#simple-arrow"
                                    ></use>
                                </svg>
                            </button>
                        </Swiper>
                        <div class="cruiser-page__information-box">
                            <h2 class="cruiser-page__information-heading">
                                {{ $t('LABELS.what-will-you-sail-on') }}
                            </h2>
                            <div
                                class="cruiser-page__information-row"
                                v-if="shipDetail.param_cabin_count"
                            >
                                <p class="cruiser-page__information-label">
                                    {{ $t('LABELS.cabins-number') }}
                                </p>
                                <p class="cruiser-page__information-value">
                                    {{ shipDetail.param_cabin_count }}
                                </p>
                            </div>
                            <div
                                class="cruiser-page__information-row"
                                v-if="shipDetail.param_length"
                            >
                                <p class="cruiser-page__information-label">
                                    {{ $t('LABELS.ship-length') }}
                                </p>
                                <p class="cruiser-page__information-value">
                                    {{ shipDetail.param_length }}m
                                </p>
                            </div>
                            <div
                                class="cruiser-page__information-row"
                                v-if="shipDetail.param_width"
                            >
                                <p class="cruiser-page__information-label">
                                    {{ $t('LABELS.ship-width') }}
                                </p>
                                <p class="cruiser-page__information-value">
                                    {{ shipDetail.param_width }}m
                                </p>
                            </div>
                            <div
                                class="cruiser-page__information-row"
                                v-if="shipDetail.param_passenger_count"
                            >
                                <p class="cruiser-page__information-label">
                                    {{ $t('LABELS.passanger-count') }}
                                </p>
                                <p class="cruiser-page__information-value">
                                    {{ shipDetail.param_passenger_count }}
                                </p>
                            </div>
                            <div
                                class="cruiser-page__information-row"
                                v-if="shipDetail.param_speed"
                            >
                                <p class="cruiser-page__information-label">
                                    {{ $t('LABELS.speed-in-knots') }}
                                </p>
                                <p class="cruiser-page__information-value">
                                    {{ shipDetail.param_speed }}
                                </p>
                            </div>
                            <div
                                class="cruiser-page__information-row"
                                v-if="shipDetail.param_tonne"
                            >
                                <p class="cruiser-page__information-label">
                                    {{ $t('LABELS.weight') }}
                                </p>
                                <p class="cruiser-page__information-value">
                                    {{ shipDetail.param_tonne }}t
                                </p>
                            </div>
                        </div>
                        <div
                            class="
                                cruiser-page__information-texts
                                cruiser-page__wysiwyg
                            "
                            v-html="shipDetail.text_one?.[LANGUAGE]"
                        ></div>
                    </div>
                </div>
            </section>
            <section
                v-if="this.shipDetail"
                class="cruises-delegate-detail__section"
            >
                <div class="cruises-delegate-detail__section-inner">
                    <div class="cruiser-page__cabins-container">
                        <div class="cruiser-page__cabins-list">
                            <h2 class="cruiser-page__cabins-heading">
                                {{ $t('LABELS.cabins') }}
                            </h2>
                            <div
                                class="cruiser-page__cabin-radio-container"
                                v-for="shipCabin in shipDetail.cabins"
                                :key="shipCabin.id"
                            >
                                <input
                                    type="radio"
                                    name="cabin"
                                    :id="shipCabin.id"
                                    :value="shipCabin.id"
                                    class="cruiser-page__cabin-radio"
                                    v-model="cabin"
                                />
                                <label
                                    :for="shipCabin.id"
                                    class="cruiser-page__cabin-label"
                                    >{{ shipCabin.name?.[LANGUAGE] }}</label
                                >
                            </div>
                        </div>
                        <div class="cruiser-page__cabins-slider-container">
                            <Swiper
                                :slides-per-view="1"
                                class="
                                    cabin-showcase__slider
                                    cruiser-page__cabin-slider
                                "
                                @swiper="saveCabinsSwiper"
                                :autoplay="{
                                    delay: 3000,
                                }"
                            >
                                <button
                                    class="
                                        cabin-showcase__slider-btn
                                        cabin-showcase__slider-btn--prev
                                    "
                                    :class="{
                                        'cabin-showcase__slider-btn--disabled':
                                            cabinsSwiper?.isBeginning,
                                        'cabin-showcase__slider-btn--hidden':
                                            cabinsSwiper?.isBeginning &&
                                            cabinsSwiper?.isEnd,
                                    }"
                                    @click="prevSlide(cabinsSwiper)"
                                >
                                    <svg
                                        class="
                                            cabin-showcase__slider-icon
                                            cabin-showcase__slider-icon--left
                                        "
                                    >
                                        <use
                                            href="/assets/imgs/icons/sprite.svg#simple-arrow"
                                        ></use>
                                    </svg>
                                </button>
                                <Swiper-slide
                                    v-for="(imgUrl, i) in cabinImages[cabin]"
                                    :key="i"
                                    ><img
                                        :src="imgUrl"
                                        class="cabin-showcase__slide"
                                /></Swiper-slide>
                                <button
                                    class="
                                        cabin-showcase__slider-btn
                                        cabin-showcase__slider-btn--next
                                    "
                                    :class="{
                                        'cabin-showcase__slider-btn--disabled':
                                            cabinsSwiper?.isEnd,
                                        'cabin-showcase__slider-btn--hidden':
                                            cabinsSwiper?.isEnd &&
                                            cabinsSwiper?.isBeginning,
                                    }"
                                    @click="nextSlide(cabinsSwiper)"
                                >
                                    <svg
                                        class="
                                            cabin-showcase__slider-icon
                                            cabin-showcase__slider-icon--right
                                        "
                                    >
                                        <use
                                            href="/assets/imgs/icons/sprite.svg#simple-arrow"
                                        ></use>
                                    </svg>
                                </button>
                            </Swiper>
                            <p
                                class="cruiser-page__cabin-slider-label"
                                v-if="cabinTexts[cabin]"
                                v-html="cabinTexts[cabin]"
                            ></p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="cruises-delegate-detail__section">
                <div
                    class="
                        cruises-delegate-detail__section-inner
                        cruises-delegate-detail__wysiwyg
                    "
                    v-html="details.description?.[LANGUAGE]"
                ></div>
            </section>
            <section class="cruises-delegate-detail__section">
                <div
                    class="
                        cruises-delegate-detail__section-inner
                        features-dash__container--grid
                    "
                >
                    <FeaturesDash
                        v-if="details?.priceIncludedText"
                        :subHeading="$t('LABELS.services-basic-price-includes')"
                        :heading="$t('LABELS.basic-price-includes')"
                        :features="
                            details.priceIncludedText.map(
                                (item) => item[LANGUAGE]
                            )
                        "
                        type="includes"
                    ></FeaturesDash>
                    <FeaturesDash
                        v-if="details?.priceExcludedText"
                        :subHeading="$t('LABELS.additional-services')"
                        :heading="$t('LABELS.basic-price-excludes')"
                        :features="
                            details.priceExcludedText.map(
                                (item) => item[LANGUAGE]
                            )
                        "
                        type="additional"
                    ></FeaturesDash>
                </div>
            </section>
            <section
                v-if="details.delegateInfo?.name?.[LANGUAGE]"
                class="
                    cruises-delegate-detail__section
                    cruises-delegate-detail__section--white
                "
            >
                <div
                    class="contact-dash cruises-delegate-detail__section-inner"
                >
                    <div class="contact-dash__part contact-dash__part--contact">
                        <div class="contact-dash__person-container">
                            <div class="contact-dash__img-container">
                                <img
                                    :src="details.delegateImage"
                                    class="contact-dash__img"
                                />
                            </div>
                            <div class="contact-dash__person-info">
                                <h3 class="contact-dash__name">
                                    {{ details.delegateInfo.name[LANGUAGE] }}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="contact-dash__part contact-dash__part--text">
                        <div class="contact-dash__info">
                            <h3 class="cruises-delegate-detail__contact-title">
                                {{ $t('LABELS.your-delegate') }}
                            </h3>
                            <p
                                class="contact-dash__info-text"
                                v-html="details.delegateInfo?.text[LANGUAGE]"
                            ></p>
                        </div>
                    </div>
                </div>
            </section>
            <section
                v-if="details.noteText?.[LANGUAGE]"
                class="cruises-delegate-detail__section"
            >
                <div
                    class="
                        cruises-delegate-detail__section-inner
                        cruises-delegate-detail__wysiwyg
                    "
                    v-html="details.noteText?.[LANGUAGE]"
                ></div>
            </section>
        </main>
    </main>
    <Footer></Footer>
    <Loading
        :is-loading="isLoading"
        :label="$t('STATUSES.loading-cruise-info')"
    ></Loading>
</template>
<script>
import {
    Swiper,
    SwiperSlide,
} from '../../../node_modules/swiper/vue/swiper-vue';

import { upperCaseFirst as upperCaseFirstUtil } from 'upper-case-first';
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import { Api } from '../../modules/Api';
import { Dictionary } from '../../modules/Dictionary';
import formatDateUtil from '../../utilities/formatDate';
import formatPriceUtil from '../../utilities/formatPrice';
import Loading from '../../components/Loading.vue';
import Banner from '../../components/Banner.vue';
import StickyHeaderCruiseDelegate from '../../components/StickyHeaderCruiseDelegate.vue';
import Gallery from '../../components/Gallery.vue';
import CabinGroupDelegate from '../../components/CabinGroupDelegate.vue';
import vSelect from 'vue-select';
import Itinerary from '../../components/Itinerary.vue';
import FeaturesDash from '../../components/FeaturesDash.vue';
import Notification from '../../components/Notification.vue';

import { Head } from '@vueuse/head';

import { TYPE, LANGUAGE } from '@/env';

const dict = new Dictionary();

export default {
    props: ['cruise_code'],
    components: {
        Footer,
        MainNav,
        Swiper,
        SwiperSlide,
        Loading,
        Banner,
        StickyHeaderCruiseDelegate,
        Head,
        Gallery,
        CabinGroupDelegate,
        vSelect,
        Itinerary,
        FeaturesDash,
        Notification,
    },
    data() {
        return {
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            TYPE: TYPE,
            details: {
                isSoldOut: {},
            },
            recommendedCruises: [],
            showStickyHeader: false,
            gallery: {
                show: false,
                images: [],
                imageIndex: 0,
            },
            swiper: null,
            isLoading: false,
            includeTransport: false,
            banner: { url: '', mobile: {}, desktop: {} },
            cruiseName: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: '',
            allowGDPR: false,
            error: {
                show: false,
                text: [],
            },
            prices: [],
            maxPeoplePerCabin: 4,
            cabinData: [],
            totalPrice: 0,
            selectedPriceIndex: null,
            addedGroups: {},
            itinerary: [],
            shipDetail: null,
            infoSwiper: null,
            cabin: 'cabin-1',
            cabinImages: {
                'cabin-1': [
                    '/assets/imgs/banners/banner-vysledky-vyhledavani-cruiser-yacht-desk-270x320.png',
                    '/assets/imgs/banners/banner-vysledky-vyhledavani-cruiser-yacht-desk-270x320.png',
                ],
                'cabin-2': [
                    '/assets/imgs/cruiser-detail-background.jpg',
                    '/assets/imgs/flights.jpg',
                ],
                'cabin-3': [
                    '/assets/imgs/yacht-detail-background-2.jpg',
                    '/assets/imgs/yacht-detail-background.jpg',
                ],
            },
            cabinTexts: {
                'cabin-1': 'Text ke kajutě 1',
                'cabin-2': null,
                'cabin-3': 'Text ke kajutě 3',
            },
            cabinsSwiper: null,
        };
    },
    methods: {
        upperCaseFirst(word) {
            return upperCaseFirstUtil(word);
        },
        saveSwiper(swiper) {
            this.swiper = swiper;
        },
        nextSlide(swiper) {
            swiper.slideNext();
        },
        prevSlide(swiper) {
            swiper.slidePrev();
        },
        inflect(word, num) {
            return dict.getWord(word, num, LANGUAGE);
        },
        daysInflected(days_num) {
            return dict.getWord('days', days_num, LANGUAGE);
        },
        formatDate(date) {
            return formatDateUtil(date);
        },
        formatPrice(price) {
            return formatPriceUtil(price);
        },
        showGallery(index) {
            this.gallery.imageIndex = index;
            this.gallery.show = true;
        },
        setGalleryImages() {
            this.gallery.images = this.details.gallery.map((img) => img.url);
        },
        priceText(type) {
            if (this.details.prices && this.details.freeUnits) {
                if (this.details.isSoldOut?.[type]) {
                    return this.$t('LABELS.soldout');
                }
                return `${this.formatPrice(
                    this.details.prices[type][this.$store.currency]
                        .amount_decimal
                )} ${this.details.prices[type][this.$store.currency].currency}`;
            }
            return '';
        },
        discountPriceMarkup(type) {
            return `
                  <span class='cruises-delegate-detail-page__price-discounted'>${this.formatPriceText(
                      this.details?.prices[type][this.$store.currency]
                  )}</span>
                  <span class='cruises-delegate-detail-page__price-original'>${this.formatPriceText(
                      this.details?.pricesFull[type][this.$store.currency]
                  )}</span>
              `;
        },
        formatPriceText(price) {
            return `${this.formatPrice(price)} ${
                this.$store.currency === 'czk'
                    ? 'Kč'
                    : this.$store.currency === 'eur'
                    ? '€'
                    : this.$store.currency
            }`;
        },
        redirectToOrderForm(type) {
            if (this.details.isSoldOut?.[type]) {
                return false;
            }

            this.$router.push(
                `/plavba-s-delegatem-objednavka/${this.details.code}/`
            );
        },
        setSoldOut() {
            const types = ['single'];

            types.forEach((type) => {
                this.details.isSoldOut[type] =
                    !this.details.prices ||
                    !this.details.freeUnits ||
                    this.details.prices[type][this.$store.currency]
                        .amount_decimal === 0 ||
                    this.details.freeUnits[type] === 0;
            });
        },
        async loadBanner() {
            this.banner =
                (await this.api.getBanner(TYPE, LANGUAGE, 'cruise')) ||
                this.banner;
        },
        async sendOrderForm() {
            this.closeNotification();

            if (
                this.firstName === '' ||
                this.lastName === '' ||
                this.email === '' ||
                this.phone === ''
            ) {
                this.error.show = true;
                this.error.text = {
                    cs: [this.$t('ERRORS.fill-required')],
                    sk: [this.$t('ERRORS.fill-required')],
                };
                window.scrollTo(0, 0);
                console.error(
                    'Missing required fields:',
                    this.firstName,
                    this.lastName,
                    this.email,
                    this.phone
                );
                return false;
            }

            // Verify phone number format
            if (this.phone.split(' ').length > 1 || this.phone.length != 9) {
                this.error.show = true;
                this.error.text = {
                    cs: [this.$t('ERRORS.wrong-phone-format')],
                    sk: [this.$t('ERRORS.wrong-phone-format')],
                };
                window.scrollTo(0, 0);
                console.error('Wrong phone number format:', this.phone);
                return false;
            }

            // Verify GDPR checkbox
            if (!this.allowGDPR) {
                window.scrollTo(0, 0);
                this.error.show = true;
                this.error.text = {
                    cs: [this.$t('ERRORS.agree-gdpr')],
                    sk: [this.$t('ERRORS.agree-gdpr')],
                };
                window.scrollTo(0, 0);
                console.error('User did not agree to GDPR');
                return false;
            }

            // Verify email ASCII
            if (
                !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(
                    this.email
                )
            ) {
                this.error.show = true;
                this.error.text = {
                    cs: [this.$t('ERRORS.invalid-email')],
                    sk: [this.$t('ERRORS.invalid-email')],
                };
                window.scrollTo(0, 0);
                console.error('Invalid email:', this.email);
                return false;
            }

            // Filter out empty cabins and cabin groups
            const filteredCabinData = Object.entries(this.cabinData)
                .map(([uuid, cabins]) => ({
                    uuid,
                    cabins: cabins.filter(
                        (cabin) => cabin.numAdults > 0 || cabin.numChildren > 0
                    ),
                }))
                .filter((group) => group.cabins.length > 0);

            // Check if there is at least one valid cabin left
            if (filteredCabinData.length === 0) {
                this.error.show = true;
                this.error.text = {
                    cs: [this.$t('ERRORS.empty-cabins')],
                    sk: [this.$t('ERRORS.empty-cabins')],
                };
                window.scrollTo(0, 0);
                console.error('No valid cabins left');
                return false;
            }

            this.isLoading = true;

            const reqBody = {
                cruiseName: this.cruiseName,
                cruiseCode: this.details?.code,
                cruiseId: this.details.id,
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                phone: this.phone,
                message: this.message,
                cabins: filteredCabinData,
                currency: this.$store.currency,
            };

            const resp = await this.api.sendCruisesDelegateOrderForm(reqBody);
            this.isLoading = false;

            if (!resp.data.send) {
                window.scrollTo(0, 0);
                this.error.show = true;
                this.error.text = {
                    cs: [this.$t('ERRORS.contact-form-send-fail')],
                    sk: [this.$t('ERRORS.contact-form-send-fail')],
                };
                window.scrollTo(0, 0);
                console.error('Contact form send failed');
                return false;
            }

            console.info('Contact form send success');
            this.$router.push('/plavba-s-delegatem-odeslano/');
        },
        closeNotification() {
            this.error.show = false;
            this.error.text = {
                cs: [],
                sk: [],
            };
        },
        updateGroupData(priceIndex, groupData) {
            groupData.uuids.forEach((uuid) => {
                delete this.cabinData[uuid];
            });

            groupData.cabins.forEach((cabin) => {
                // If this is the first cabin with this UUID, initialize the array
                if (!this.cabinData[cabin.uuid]) {
                    this.cabinData[cabin.uuid] = [];
                }
                // Add the cabin to the array for its UUID
                this.cabinData[cabin.uuid].push(cabin);
            });

            if (groupData.cabins.length === 0) {
                delete this.addedGroups[groupData.code];
            }

            this.calculateTotalPrice();
        },
        calculateTotalPrice() {
            this.totalPrice = Object.values(this.cabinData).reduce(
                (sum, cabins) => {
                    return (
                        sum +
                        cabins.reduce(
                            (groupSum, cabin) => groupSum + cabin.totalPrice,
                            0
                        )
                    );
                },
                0
            );
        },
        handleAddPrice() {
            if (this.selectedPriceIndex === null) return;

            const selectedPriceCode =
                this.prices[this.selectedPriceIndex][0].code;

            if (
                Object.prototype.hasOwnProperty.call(
                    this.addedGroups,
                    selectedPriceCode
                )
            ) {
                // Price code already exists in addedGroups
                this.callExistingGroupAdd(selectedPriceCode);
            } else {
                // Add the selected price code to addedGroups
                this.addedGroups[selectedPriceCode] = this.selectedPriceIndex;
            }
        },
        callExistingGroupAdd(code) {
            this.$refs['cabinGroup_' + code].addCabin();
        },
        saveInfoSwiper(swiper) {
            this.infoSwiper = swiper;
        },
    },
    async mounted() {
        this.isLoading = true;
        this.loadBanner();

        this.details = {
            ...this.details,
            ...(await this.api.getCruisesDelegateDetail(this.cruise_code)),
        };

        this.prices = await this.api.getSpecialCruisePrices(this.details.id);

        if (this.prices.length > 0) {
            this.selectedPriceIndex = 0;
        }

        this.itinerary = await this.api.getSpecialCruiseItinerary(
            this.details.id
        );

        if (
            this.details.transports?.bus?.[LANGUAGE] ||
            this.details.transports?.plane?.[LANGUAGE]
        ) {
            this.includeTransport = true;
        }

        if (!this.details?.code) {
            this.$router.push(
                `/plavba-s-delegatem/${this.cruise_code}/nenalezeno/`
            );
        }

        this.shipDetail = await this.api.getShipDetail(this.details.ship_code);

        if (this.shipDetail && this.shipDetail.cabins.length > 0) {
            this.cabin = this.shipDetail.cabins[0].id;

            this.shipDetail.cabins.forEach((cabin) => {
                this.cabinImages[cabin.id] = cabin.cabinImages;
            });

            this.shipDetail.cabins.forEach((cabin) => {
                this.cabinTexts[cabin.id] = cabin.description[LANGUAGE];
            });
        }

        this.cruiseName;

        this.setSoldOut();
        this.setGalleryImages();

        //this.swiper.slideReset();
        this.isLoading = false;
    },
    computed: {
        urlCrewSearch() {
            const query = this.$route.query;
            return `?adultsNum=${query.adultsNum}`;
        },
        urlCrewHash() {
            const query = this.$route.query;
            return `#adultsNum=${query.adultsNum}`;
        },
        discountPriceMarkupComputed() {
            return this.discountPriceMarkup('single');
        },
        priceObjects() {
            return this.prices.map((priceArray) => ({ fares: priceArray }));
        },
    },
    watch: {
        async $route(newRoute) {
            this.details = await this.api.getCruisesDelegateDetail(
                newRoute.params.cruise_code
            );

            if (!this.details && this.$route.path.startsWith('/detail/')) {
                this.$router.push(
                    `/plavba-s-delegatem/${this.cruise_code}/nenalezeno/`
                );
            }
        },
    },
};
</script>
